import { render, staticRenderFns } from "./BaseThemeSwitch.vue?vue&type=template&id=00e3a653&scoped=true"
import script from "./BaseThemeSwitch.vue?vue&type=script&lang=js"
export * from "./BaseThemeSwitch.vue?vue&type=script&lang=js"
import style0 from "./BaseThemeSwitch.vue?vue&type=style&index=0&id=00e3a653&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00e3a653",
  null
  
)

export default component.exports