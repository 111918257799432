<template>
  <v-app-bar app dark color="secondary">
    <BaseNavDesktop
      v-if="windowWidth > mobileWidth"
      :menuItems="menuItems"
      :isAdmin="isAdmin"
      :userRoles="userRoles"
      :navigateTo="navigateTo"
    />
    <!-- Menu lateral para view mobile -->
    <BaseNavMobile
      v-else
      :menuItems="menuItems"
      :isAdmin="isAdmin"
      :userRoles="userRoles"
      :navigateTo="navigateTo"
      @redirectHome="redirectHome"
      @update:drawer="drawer = $event"
    />
  </v-app-bar>
</template>

<script>
import navBarRoutes from "@/router/navbar";
import BaseNavMobile from "@/components/BaseNavMobile.vue";
import BaseNavDesktop from "@/components/BaseNavDesktop.vue";

import { storeToRefs } from "pinia";

import { useNotificationStore } from "@/store/notification.store";

export default {
  data() {
    return {
      menuItems: navBarRoutes,
      isAdmin: localStorage.getItem("roles")?.includes("ROLE_ADMIN"),
      userRoles: localStorage.getItem("roles"),
      notificationsOpen: false,
      username: "",
      mobileWidth: 1963,
      windowWidth: window.innerWidth,
    };
  },
  components: {
    BaseNavMobile,
    BaseNavDesktop,
  },

  setup() {
    const store = useNotificationStore();

    const { notifications, connect } = storeToRefs(store);

    return {
      notifications,
      connect,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },

  mounted() {
    const localStorageUsername = localStorage.getItem("username");
    if (this.localStorageUsername) {
      this.username = localStorageUsername
        .toLowerCase()
        .replace(/\b\w/g, (c) => c.toUpperCase());
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    navigateTo(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    redirectHome() {
      if (["Index"].includes(this.$route.name)) {
        return;
      }
      this.$router.push("/");
    },
  },
};
</script>
