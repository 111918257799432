export const PedidosRoutes = [
  {
    path: "/pedidos/dashboard",
    name: "PedidosDashboard",
    component: () => import("@/views/pedidos/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pedidos/importacao/magento",
    name: "PedidosImportacaoError",
    component: () => import("@/views/PedidoImportacaoErrorList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pedidos/rma",
    name: "Pedidos RMA",
    component: () => import("@/views/PedidosRmaList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pedidos/relacao-subst-produtos",
    name: "PedidosRelacaoSubstProdutos",
    component: () => import("@/views/PedidosRelacaoSubstDeProdutosList"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pedidos/list",
    name: "Pedidos",
    component: () => import("@/views/PedidosList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pedidos/view",
    name: "Novo Pedido",
    component: () => import("@/views/PedidosView.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/pedidos/lista/novo/item",
    name: "",
    component: () => import("@/components/PedidosFormItemDialog.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pedidos/liberacao/lista",
    name: "",
    component: () => import("@/views/PedidosLiberacaoLista.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];
