const navBarRoutes = [
  {
    title: "Sistema",
    role: "ROLE_SISTEMA",
    icon: "mdi-cog-outline",
    items: [
      {
        name: "Dúvidas e Bugs",
        role: ["ROLE_FAQ", "ROLE_ADMIN"],
        icon: "mdi-help",
        path: "/sistema/faq",
      },
      {
        name: "Agendamentos",
        role: "ROLE_SISTEMA_AGENDAMENTOS",
        icon: "mdi-calendar-refresh-outline",
        path: "/sistema/agendamentos",
      },
    ],
  },
  {
    title: "RMA",
    role: "ROLE_RMA_ADMIN",

    icon: "mdi-clipboard-list-outline",
    items: [
      {
        name: "Processos de RMA",
        path: "/rma/processos",
        role: "ROLE_RMA_ADMIN",
        icon: "mdi-clipboard-list-outline",
      },
      {
        name: "Definindo Transportadora",
        path: "/rma/transportadora",
        role: "ROLE_RMA_ADMIN",
        icon: "mdi-truck-delivery",
      },
      {
        name: "Aguardando Emissão de Nota",
        path: "/rma/emissao-nota",
        role: "ROLE_RMA_ADMIN",
        icon: "mdi-file-document-edit",
      },
      {
        name: "RMAs Recebidos",
        path: "/rma/recebidos",
        role: "ROLE_RMA_ADMIN",
        icon: "mdi-clipboard-check",
      },
      {
        name: "Ordens de Serviço",
        path: "/rma/servicos",
        role: "ROLE_RMA_ADMIN",
        icon: "mdi-wrench",
      },
      {
        name: "Solicitações de Troca",
        path: "/rma/solicitacao-troca",
        role: "ROLE_RMA_ADMIN",
        icon: "mdi-reload",
      },
      {
        name: "Relatório de Substituição",
        path: "/rma/relatorios/substituicao",
        role: "ROLE_RMA_ADMIN",
        icon: "mdi-file-chart",
      },
    ],
  },
  {
    title: "Financeiro",
    role: "ROLE_FINANCEIRO",
    icon: "mdi-cash-multiple",
    items: [
      {
        name: "Caixa",
        path: "/financeiro/caixa",
        role: "ROLE_CAIXA",
        icon: "mdi-cash-register",
      },
      {
        name: "Resumo Saldos Bancários",
        path: "/financeiro/caixa/resumos-saldo-conta",
        role: "ROLE_FINANCEIRO_RESUMO_SALDO_BANCO",
        icon: "mdi-cash-multiple",
      },
      {
        name: "Contas a Pagar",
        path: "/financeiro/contas-pagar",
        role: "ROLE_MOVPAG_LIST",
        icon: "mdi-cash-off",
      },
      {
        name: "Contas a Receber",
        path: "/financeiro/contas-receber",
        role: "ROLE_LIST_CONTAS_RECEBER",
        icon: "mdi-cash-plus",
      },
      {
        name: "Borderô",
        path: "/financeiro/bordero",
        role: "ROLE_BORDERO",
        icon: "mdi-file-document-arrow-right",
      },
      {
        name: "Movimentos Centro Custo",
        path: "/financeiro/movimentos/centro-custo",
        role: "ROLE_MOVIMENTOS_CENTRO_CUSTO",
        icon: "mdi-animation",
      },
      {
        name: "Conciliação Marketplace",
        path: "/financeiro/conciliacao-marketplace",
        role: "ROLE_FINANCEIRO_CONCILIACAO_MKT",
        icon: "mdi-cash-multiple",
      },
      {
        name: "Conciliação Mkt",
        path: "/financeiro/conciliacao-mkt",
        role: "ROLE_FINANCEIRO_CONCILIACAO_MKT",
        icon: "mdi-cash-multiple",
      },
      {
        name: "Reembolsos",
        path: "/financeiro/refunds",
        role: "ROLE_FINANCEIRO_REFUNDS",
        icon: "mdi-cash-refund",
      },
      {
        name: "Extrato Marketplace",
        path: "/financeiro/extrato-marketplace",
        role: "ROLE_FINANCEIRO_EXTRATO",
        icon: "mdi-invoice-text-outline",
      },
      {
        name: "Finalidade Pagamento",
        path: "/financeiro/finalidade/list",
        role: "ROLE_FINALIDADE_PAG_LIST",
        icon: "mdi-credit-card-outline",
      },
      {
        name: "Contrato de Câmbio",
        path: "/contrato-cambio/list",
        role: "ROLE_CONTRATO_CAMBIO_LIST",
        icon: "mdi-handshake",
      },
      {
        name: "Conta Corrente",
        path: "/financeiro/conta-corrente",
        role: "ROLE_CONTA_CORRENTE",
        icon: "mdi-account-circle-outline",
        subitems: [
          {
            name: "Conta Corrente Pessoa",
            path: "/financeiro/conta-corrente/cliente",
            role: "ROLE_CONTA_CORRENTE_LIST",
            icon: "mdi-cash-multiple",
          },
          {
            name: "Conta Corrente Resumo",
            path: "/financeiro/conta-corrente/resumo",
            role: "ROLE_CONTA_CORRENTE_LIST",
            icon: "mdi-account-details-outline",
          },
        ],
      },
    ],
  },
  {
    title: "Fiscal",
    role: "ROLE_FISCAL",
    icon: "mdi-percent",
    items: [
      {
        name: "Notas Fiscais",
        path: "/fiscal/nota",
        role: "ROLE_FISCAL_NOTAS",
        icon: "mdi-receipt-text-plus-outline",
      },
      {
        name: "Carta de Correção",
        path: "/fiscal/cce",
        role: "ROLE_FISCAL_CCE",
        icon: "mdi-email-edit-outline",
      },
      {
        name: "Nota Fiscal Entrada",
        path: "/fiscal/nota-entrada",
        role: "ROLE_FISCAL_ENTRADA_LIST",
        icon: "mdi-file-document-arrow-right-outline",
      },
      {
        name: "Import. NF Serviço",
        path: "/fiscal/import/nota-servico/batch/list",
        role: "ROLE_FISCAL_IMPORT_SERVICOS",
        icon: "mdi-chart-line",
      },
      {
        name: "Apurações",
        role: "ROLE_APURACAO_IMPOSTOS",
        icon: "mdi-hand-coin",
        subitems: [
          {
            name: "IPI",
            path: "/fiscal/apuracao/ipi/registros",
            role: "ROLE_APURACAO_IMPOSTOS",
            icon: "mdi-hand-coin",
          },
        ],
      },
    ],
  },
  {
    title: "Pedidos",
    role: "ROLE_PEDIDOS",
    icon: "mdi-archive",
    items: [
      {
        name: "Dashboard",
        path: "/pedidos/dashboard",
        role: "ROLE_PEDIDOS_DASHBOARD",
        icon: "mdi-view-dashboard-outline",
      },
      {
        name: "Lista de Pedidos",
        path: "/pedidos/list",
        role: "ROLE_PEDIDOS_LISTA",
        icon: "mdi-view-list-outline",
      },
      {
        name: "Pedidos RMA",
        path: "/pedidos/rma",
        role: "ROLE_PEDIDOS_LISTA",
        icon: "mdi-glass-fragile",
      },
      {
        name: "Liberação de Pedidos",
        path: "/pedidos/liberacao/lista",
        role: "ROLE_PEDIDOS_IMPORTACAO",
        icon: "mdi-check-all",
      },
      {
        name: "Importação Magento",
        path: "/pedidos/importacao/magento",
        role: "ROLE_PEDIDOS_IMPORTACAO",
        icon: "mdi-import",
      },
      {
        name: "Relação de Substituições de Produtos",
        path: "/pedidos/relacao-subst-produtos",
        role: "ROLE_PEDIDOS_REPORT_SUBS",
        icon: "mdi-package-variant-closed",
      },
    ],
  },
  {
    title: "Compras",
    icon: "mdi-shopping",
    role: "ROLE_COMPRAS",
    items: [
      {
        name: "Processos",
        path: "/compras/processos",
        role: "ROLE_PROCESSOS_LIST",
        icon: "mdi-ferry",
      },
      {
        name: "Ordens de Compras",
        role: "ROLE_ORDEM_COMPRAS",
        icon: "mdi-list-box-outline",
        subitems: [
          {
            name: "Novas",
            path: "/compras/ordem-compra",
            icon: "mdi-shopping",
            role: "ROLE_ORDEM_COMPRA_LIST",
          },
          {
            name: "Antigas",
            icon: "mdi-shopping-search-outline",
            path: "/compras/ordem-compra/old",
            role: "ROLE_ORDEM_COMPRA_OLD_LIST",
          },
        ],
      },
    ],
  },
  {
    title: "Cadastros",
    role: "ROLE_CADASTROS",
    icon: "mdi-format-float-right",
    items: [
      {
        name: "Pessoas",
        path: "/cadastros/pessoas",
        role: "ROLE_CADASTRO_PESSOAS",
        icon: "mdi-account",
      },
      {
        name: "Fornecedores",
        path: "/cadastros/fornecedores",
        role: "ROLE_CADASTRO_FORNECEDOR",
        icon: "mdi-human-dolly",
      },
      {
        name: "Históricos",
        path: "/cadastros/historico",
        role: "ROLE_CADASTRO_HISTORICO_LIST",
        icon: "mdi-history",
      },
      {
        name: "Transportadoras",
        path: "/cadastros/transportadoras",
        icon: "mdi-truck-delivery",
        role: "ROLE_CADASTRO_TRANSPORTADORAS",
      },
      {
        name: "Clientes",
        path: "/cadastros/clientes",
        role: "ROLE_CADASTRO_CLIENTES",
        icon: "mdi-account-star",
      },
      {
        name: "Moedas",
        icon: "mdi-hand-coin-outline",
        path: "/cadastros/moedas",
        role: "ROLE_CADASTRO_CURRENCY",
      },
      {
        name: "Financeiro",
        role: "ROLE_CADASTRO_FINANCEIRO",
        icon: "mdi-cash-register",
        subitems: [
          {
            name: "Bancos",
            icon: "mdi-bank-plus",
            path: "/cadastros/financeiro/bancos",
            role: "ROLE_CADASTRO_BANCO",
          },
          {
            name: "Condições de Pagamento",
            icon: "mdi-account-credit-card-outline",
            path: "/cadastros/financeiro/condicoes-pagamento",
            role: "ROLE_CADASTRO_CONDICAO_PAGAMENTO",
          },
          {
            name: "Adm. de Cartões",
            icon: "mdi-credit-card-plus-outline",
            path: "/cadastros/financeiro/administradora-cartoes",
            role: "ROLE_CADASTRO_ADMINISTRADORA_CARTAO",
          },
          {
            name: "Centros de Custo",
            icon: "mdi-plus-circle-multiple-outline",
            path: "/cadastros/financeiro/centro-custo",
            role: "ROLE_CADASTRO_CENTRO_CUSTO",
          },
          {
            name: "ISPB",
            path: "/cadastros/ispb",
            icon: "mdi-bank-transfer",
            role: "ROLE_CADASTRO_ISPB",
          },
          {
            name: "Formas de Pagamento",
            path: "/cadastros/formaPagamento/list",
            icon: "mdi-cash-plus",
            role: "ROLE_CADASTRO_FORMA_PAGAMENTO",
          },
          {
            name: "Tipos de Pagamento",
            path: "/cadastros/tipos-pagamento",
            icon: "mdi-format-list-bulleted-type",
            role: "ROLE_CADASTRO_TIPO_PAGAMENTO",
          },
        ],
      },
      {
        name: "Fiscais",
        icon: "mdi-percent",
        role: "ROLE_CADASTRO_FISCAL",
        subitems: [
          {
            name: "CFOP",
            path: "/cadastros/fiscais/cfop",
            icon: "mdi-percent",
            role: "ROLE_CADASTRO_CFOP",
          },
          {
            name: "Observações",
            path: "/cadastros/fiscais/observacoes",
            icon: "mdi-note-plus-outline",
            role: "ROLE_CADASTRO_FISCAL_OBSERVACAO",
          },
          {
            name: "NCM",
            icon: "mdi-percent",
            path: "/cadastros/fiscais/ncm",
            role: "ROLE_CADASTRO_NCM",
          },
          {
            name: "CEST",
            icon: "mdi-percent",
            path: "/cadastros/fiscais/cest",
            role: "ROLE_CADASTRO_CEST",
          },
          {
            name: "Documentos Fiscais",
            path: "/cadastros/documentos/fiscais",
            icon: "mdi-file-document-outline",
            role: "ROLE_CADASTRO_DOCUMENTO_FISCAL",
          },
          {
            name: "Impostos",
            path: "/cadastros/fiscais/impostos",
            icon: "mdi-currency-usd",
            role: "ROLE_CADASTRO_SUBSTRIB_LIST",
          },
          {
            name: "Tipo de Movimento Fiscal",
            path: "/cadastro/tipo-movimento-fiscal",
            icon: "mdi-file-document-multiple-outline",
            role: "ROLE_CADASTRO_TIPO_VENDA",
          },
        ],
      },
      {
        name: "Plano de Contas",
        icon: "mdi-format-float-right",
        role: "ROLE_CADASTRO_PLANO_CONTAS",
        subitems: [
          {
            name: "Plano de Contas",
            icon: "mdi-format-float-right",
            path: "/cadastros/plano-contas/plano-contas",
            role: "ROLE_CADASTRO_PLANO_CONTAS",
          },
          {
            name: "Plano de Contas Referencial",
            icon: "mdi-format-float-right",
            path: "/cadastros/plano-contas/plano-contas-referencial",
            role: "ROLE_CADASTRO_PLANO_CONTAS_REF",
          },
        ],
      },
      {
        name: "Localidades",
        role: "ROLE_CADASTRO_LOCALIDADES",
        icon: "mdi-map-marker-plus-outline",
        subitems: [
          {
            name: "Países",
            icon: "mdi-map-outline",
            path: "/cadastros/localidades/paises",
            role: "ROLE_CADASTRO_PAISES",
          },
          {
            name: "Estados",
            icon: "mdi-sign-real-estate",
            path: "/cadastros/localidades/estados",
            role: "ROLE_CADASTRO_ESTADOS",
          },
          {
            name: "Cidades",
            icon: "mdi-city-variant-outline",
            path: "/cadastros/localidades/cidades",
            role: "ROLE_CADASTRO_CIDADES",
          },
        ],
      },
      {
        name: "Produtos",
        role: "ROLE_CADASTRO_MATERIAL",
        icon: "mdi-package-variant-closed",
        subitems: [
          {
            name: "Locais de Estoque",
            path: "/cadastros/local/estoque",
            icon: "mdi-store-outline",
          },
          {
            name: "Produtos",
            path: "/cadastros/produtos",
            icon: "mdi-package-variant-closed-plus",
            role: "ROLE_CADASTRO_MATERIAL",
          },
          {
            name: "Grupos de Produtos",
            path: "/cadastros/grupos/produtos",
            icon: "mdi-format-list-group-plus",
            role: "ROLE_CADASTRO_MATERIAL_GRUPO",
          },
          {
            name: "Unidade de Medidas",
            path: "/cadastros/produto/unidade-medida",
            icon: "mdi-weight-kilogram",
            role: "ROLE_CADASTRO_MATERIAL_UNIDADE",
          },
          {
            name: "Cadastrar Seriais",
            path: "/cadastros/produtos/seriais",
            icon: "mdi-barcode",
            role: "",
          },
        ],
      },
    ],
  },
  {
    title: "Logística",
    role: "ROLE_LOGISTICA",
    icon: "mdi-truck",
    items: [
      {
        name: "Faturas",
        path: "/logistica/fatura-transportadora",
        icon: "mdi-truck-fast",
        role: "ROLE_FATURA_TRANSP",
      },
      {
        name: "CTe",
        path: "/logistica/conhecimento-transporte",
        icon: "mdi-truck-fast",
        role: "ROLE_CONHECIMENTO_TRANSPORTE",
      },
    ],
  },
  {
    title: "RH",
    role: "ROLE_RH",
    icon: "mdi-account-group",
    items: [
      {
        name: "Diário de Horas",
        path: "/rh/diario-horas",
        icon: "mdi-hours-24",
        role: "ROLE_RH_DIARIO_HORAS",
      },
    ],
  },
  {
    title: "Usuários",
    role: "ROLE_SYSTEM_USERS",
    icon: "mdi-account-circle",
    items: [
      {
        name: "Lista",
        path: "/users/list",
        role: "ROLE_SYSTEM_USERS_LIST",
        icon: "mdi-account-plus",
      },
      {
        name: "Lista AD",
        icon: "mdi-account-settings",
        role: "ROLE_SYSTEM_USERS_LIST_AD",
        path: "/users/list/ad",
      },
      {
        name: "Perfil",
        path: "/users/profiles/list",
        icon: "mdi-account-multiple-outline",
        role: "ROLE_PROFILE_LIST",
      },
    ],
  },
];

export default navBarRoutes;
