import { Buffer } from "buffer";
import API from "@/apis/api";
import { API_TYPES } from "@/apis/apiTypes";

const erpApi = new API(API_TYPES.ERP);

export async function login(user) {
  const token = Buffer.from(
    `${user.username},${user.empresaId}:${user.password}`,
    "utf8"
  ).toString("base64");

  try {
    const response = await erpApi.axios.get("/login", {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });

    if (response.headers.authorization) {
      const authToken = response.headers.authorization;

      localStorage.setItem("userToken", authToken);
      localStorage.setItem("roles", `"${response.data.permissions}"`);
      localStorage.setItem("empresas", JSON.stringify(response.data.empresas));
      localStorage.setItem("userId", response.data.id);
      localStorage.setItem("username", response.data.cn);

      return authToken;
    }

    throw new Error("Authorization header not found in response");
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
}

export function logout() {
  localStorage.removeItem("userToken");
  localStorage.removeItem("roles");
  localStorage.removeItem("empresas");
  localStorage.removeItem("userId");
  localStorage.removeItem("username");
}

export function isAuthenticated() {
  return localStorage.getItem("userToken") !== null;
}
