import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const savedDarkMode = JSON.parse(localStorage.getItem("darkMode")) || false;

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#1976D2",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
        background: "#FFFFFF",
      },
      dark: {
        primary: "#1E88E5",
        secondary: "#424242",
        accent: "#FF4081",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
        background: "#121212",
      },
    },
    options: { customProperties: true },
    dark: savedDarkMode,
  },
});

vuetify.framework.theme.dark = savedDarkMode;

export default vuetify;
