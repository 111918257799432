export const FiscalRoutes = [
  {
    path: "/fiscal/import/nota-servico/batch/list",
    name: "FiscalImportNotaServicoBatchList",
    component: () => import("@/views/FiscalImportNotaServicoBatchList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/fiscal/import/nota-servico/batch/view",
    name: "FiscalImportNotaServicoBatchItems",
    component: () => import("@/views/FiscalImportNotaServicoBatchItems.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/fiscal/import/nota-servico",
    name: "FiscalImportNotaServico",
    component: () => import("@/views/FiscalImportNotaServico.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/fiscal/apuracao/ipi/registros",
    name: "FiscalApuracaoIpiList",
    component: () => import("@/views/FiscalApuracaoIpiList.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/fiscal/apuracao/ipi/dif-apuracao",
    name: "FiscalApuracaoIpiDifList",
    component: () => import("@/views/FiscalApuracaoIpiDifList.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/fiscal/nota",
    name: "NotaFiscalList",
    component: () => import("@/views/NotaFiscalList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/fiscal/nota/view",
    name: "NotaFiscalView",
    component: () => import("@/views/FiscalNotaFiscalView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/fiscal/cce",
    name: "FiscalNotaCartaCorrecaoList",
    component: () => import("@/views/FiscalNotaCartaCorrecaoList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/fiscal/nota-entrada",
    name: "FiscalNotaEntradaList",
    component: () => import("@/views/FiscalNotaEntradaList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/fiscal/nota-entrada/view",
    name: "FiscalNotaEntradaView",
    component: () => import("@/views/FiscalNotaEntradaView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/fiscal/nota-entrada/view/item",
    name: "FiscalNotaEntradaItemView",
    component: () => import("@/views/FiscalNotaEntradaDetalhesItem.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/fiscal/nota/view/item",
    name: "FiscalNotaFiscalDetalhesItem",
    component: () => import("@/views/FiscalNotaFiscalDetalhesItem.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/fiscal/apuracao/ipi/detalhado",
    name: "FiscalApuracaoIpiDetalhado",
    component: () => import("@/views/FiscalApuracaoIpiDetalhado"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
];
