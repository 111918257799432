import { piniaEventBusPlugin } from "@/plugins/piniaCustomPlugin";
import axios from "axios";
import { createPinia } from "pinia";
import VueMask from "v-mask";
import money from "v-money";
import Vue from "vue";
import Notifications from "vue-notification";
import "../global.css";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";

const pinia = createPinia();

pinia.use(piniaEventBusPlugin);

Vue.prototype.axios = axios.create({
  withCredentials: false,
});
Vue.config.productionTip = false;
Vue.use(Notifications);
Vue.use(VueMask);
Vue.use(money, {
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  precision: 2,
});
Vue.use(pinia);

new Vue({
  router,
  vuetify,
  pinia,
  render: (h) => h(App),
}).$mount("#app");
