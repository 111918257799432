<template>
  <v-menu v-model="notificationsOpen" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        rounded
        icon
        small
        plain
        class="ml-2"
        elevation="0"
      >
        <v-badge
          :content="receivedMessages.length"
          :color="receivedMessages.length > 0 ? 'red' : ''"
          overlap
        >
          <v-icon
            :dark="$vuetify.theme.dark"
            :size="receivedMessages.length > 0 ? '26' : '20'"
          >
            mdi-bell
          </v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-list dense>
      <template v-if="receivedMessages.length > 0">
        <v-list-item
          v-for="item in receivedMessages"
          :key="item.processId || item.id"
        >
          <v-list-item-content>
            <v-list-item-title v-if="item.class === 'progress'">
              {{ getLabel(item) }}: {{ getPercentual(item) }}%
            </v-list-item-title>
            <v-progress-linear
              v-if="item.class === 'progress'"
              :value="getPercentual(item)"
              color="green"
              height="10"
              class="mt-2"
              style="width: 100%"
            />
            <v-list-item-title v-else>
              {{ item.message || item }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item>
          <v-list-item-title>Você não possui notificações</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { useNotificationStore } from "@/store/notification.store";
import { storeToRefs } from "pinia";
import RedisTopics from "@/util/constants/RedisTopics";

export default {
  data() {
    return {
      notificationsOpen: false,
    };
  },
  setup() {
    const store = useNotificationStore();
    const { receivedMessages } = storeToRefs(store);

    const translatedTopic = (type) => RedisTopics[type] || type;

    const parseNotification = (notification) => {
      try {
        return typeof notification.notification === "string"
          ? JSON.parse(notification.notification)
          : notification.notification;
      } catch (error) {
        console.error("Erro ao parsear notificação:", notification, error);
        return {};
      }
    };

    const getPercentual = (notification) => {
      const parsed = parseNotification(notification);
      return parsed?.percentual ?? 0;
    };

    const getLabel = (notification) => {
      return RedisTopics[notification?.type] ?? notification?.type ?? "";
    };

    return { receivedMessages, translatedTopic, getPercentual, getLabel };
  },
};
</script>
