var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"d-flex align-center justify-between",attrs:{"fluid":""}},[_c('v-col',{staticClass:"d-flex"},[_c('BaseNavLogo',{staticClass:"logo-img",attrs:{"width":"200","height":"50"}})],1),_c('v-col',{staticClass:"d-flex mt-4"},[_c('p',{staticClass:"environment-label",domProps:{"innerHTML":_vm._s(_vm.erpBaseFilters)}})]),_c('v-row',{staticClass:"d-flex justify-end align-center"},[_c('v-col',{staticClass:"mr-2",attrs:{"cols":"auto"}},[_c('BaseNotification')],1),_c('v-col',{staticClass:"mr-2",attrs:{"cols":"auto"}},[_c('BaseThemeSwitch')],1),_vm._l((_vm.menuItems),function(item,idx){return _c('v-col',{key:`menu-${idx}`,attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(
              _vm.isAdmin ||
              (Array.isArray(item.role)
                ? item.role.some((r) => _vm.userRoles.includes(r))
                : _vm.userRoles?.includes(item.role))
            ),expression:"\n              isAdmin ||\n              (Array.isArray(item.role)\n                ? item.role.some((r) => userRoles.includes(r))\n                : userRoles?.includes(item.role))\n            "}],staticClass:"no-uppercase",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title)+" ")],1)]}}],null,true)},[_c('v-list',{staticClass:"menu-list"},_vm._l((item.items),function(subItem,subIdx){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.isAdmin ||
              (Array.isArray(subItem.role)
                ? subItem.role.some((r) => _vm.userRoles.includes(r))
                : _vm.userRoles?.includes(subItem.role))
            ),expression:"\n              isAdmin ||\n              (Array.isArray(subItem.role)\n                ? subItem.role.some((r) => userRoles.includes(r))\n                : userRoles?.includes(subItem.role))\n            "}],key:`subGroup-${subIdx}`},[(subItem.subitems?.length > 0)?_c('v-list-group',{attrs:{"title":subItem.name,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{on:{"click":function($event){subItem.subitems ? null : _vm.navigateTo(subItem.path)}}},[_c('v-icon',[_vm._v(_vm._s(subItem.icon))]),_vm._v(" "+_vm._s(subItem.name)+" ")],1)]},proxy:true},{key:"append-icon",fn:function(){return [_c('v-icon',[_vm._v(_vm._s("mdi-chevron-down"))])]},proxy:true}],null,true)},_vm._l((subItem.subitems),function(child,childIdx){return _c('v-list-item',{key:`child-${childIdx}`,staticClass:"menu-list-item",on:{"mousedown":function($event){return _vm.handleMouseDown($event, child.path)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v(_vm._s(child.icon))]),_vm._v(" "+_vm._s(child.name)+" ")],1)],1)}),1):_c('v-list-item',{staticClass:"menu-list-item",on:{"mousedown":function($event){return _vm.handleMouseDown($event, subItem.path)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v(_vm._s(subItem.icon))]),_vm._v(" "+_vm._s(subItem.name)+" ")],1)],1)],1)}),0)],1)],1)}),_c('v-col',{attrs:{"cols":"auto"}},[_c('BaseNavLogout')],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }