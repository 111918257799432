import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import jwtDecode from "vue-jwt-decode";
import { showLoading, hideLoading } from "@/util/loading";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  showLoading();

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("userToken") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      const token = localStorage.getItem("userToken");
      const jwtDecoded = jwtDecode.decode(token);

      if (!jwtDecoded) {
        next({
          path: "/login",
          params: { nextUrl: to.fullPath },
        });
      }

      let expiryDate = new Date(jwtDecoded.exp * 1000);
      let currentDate = new Date();
      if (currentDate > expiryDate) {
        next({
          path: "/login",
          params: { nextUrl: to.fullPath },
        });
      }
      next();
    }
  } else {
    next();
  }
  hideLoading();
});

export default router;
