import { eventBus } from "./eventBus";

export function handleWebSocketMessage(message, notificationType) {
  if (!message || !message.type) {
    console.error("Mensagem inválida");
    return;
  }

  const notification = JSON.parse(message.notification);

  eventBus.emit(notificationType, {
    content: notification.data,
    event: message.type,
  });
}
