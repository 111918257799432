<template>
  <v-switch
    v-if="$vuetify.breakpoint.smAndDown"
    v-model="isLightMode"
    :append-icon="isLightMode ? 'mdi-weather-sunny' : ''"
    :prepend-icon="!isLightMode ? 'mdi-weather-night' : ''"
    flat
    dark
    light
    persistent-hint
    class="theme-switch"
    data-cy="theme-switch"
    hint="Tema"
    color="#FFD700"
  />
  <div v-else class="theme-toggle" @click="toggleTheme">
    <v-icon
      color="yellow"
      :class="['toggle-icon', { 'is-visible': isLightMode }]"
    >
      mdi-weather-sunny
    </v-icon>
    <v-icon
      color="blue"
      :class="['toggle-icon', { 'is-visible': !isLightMode }]"
    >
      mdi-weather-night
    </v-icon>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLightMode: JSON.parse(localStorage.getItem("darkMode")) !== true,
    };
  },
  watch: {
    isLightMode(newVal) {
      this.$vuetify.theme.dark = !newVal;
      localStorage.setItem("darkMode", JSON.stringify(!newVal));
    },
  },
  methods: {
    toggleTheme() {
      this.isLightMode = !this.isLightMode;
      this.$vuetify.theme.dark = !this.isLightMode;
      localStorage.setItem("darkMode", JSON.stringify(!this.isLightMode));
    },
  },
  mounted() {
    this.$vuetify.theme.dark = !this.isLightMode;
  },
};
</script>

<style scoped>
.theme-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: var(--v-theme-surface);
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  transition: background-color 0.3s ease;
}

.toggle-icon {
  font-size: 24px;
  position: absolute;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  transform: translateY(50px);
}

.toggle-icon.is-visible {
  opacity: 1;
  transform: translateY(0);
}
.theme-switch {
  margin: 0 32px -20px;
}
</style>
