export const CadastrosRoutes = [
  {
    path: "/cadastros/localidades/paises",
    name: "Lista - Países",
    component: () => import("@/views/CadastroLocalidadesPaisesList.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/cadastros/localidades/estados",
    name: "Estados",
    component: () => import("@/views/CadastroLocalidadesEstadosList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/localidades/cidades",
    name: "Cidade",
    component: () => import("@/views/CadastroLocalidadeCidadesList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/fornecedores",
    name: "Fornecedores List",
    component: () => import("@/views/CadastroFornecedoresList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/fornecedores/view",
    name: "Novo Fornecedor",
    component: () => import("@/views/CadastroFornecedoresView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/transportadoras",
    name: "Lista Transportadoras",
    component: () => import("@/views/CadastroTransportadorasList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/transportadoras/view",
    name: "NewCarriers",
    component: () => import("@/views/CadastroTransportadorasView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/pessoas/view",
    name: "Cadastro Pessoas",
    component: () => import("@/views/CadastroPessoaView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/modalidades/transportadoras/view",
    name: "NewModalitiesCarriers",
    component: () =>
      import("@/views/cadastros/modalitiesCarriers/NewModalitiesCarriers.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/financeiro/bancos",
    name: "ListBank",
    component: () => import("@/views/CadastroBancoList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/financeiro/bancos/view",
    name: "Banco",
    component: () => import("@/views/CadastroBancoView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/plano-contas/plano-contas",
    name: "PlanoContasList",
    component: () => import("@/views/CadastroPlanoContasList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/plano-contas/plano-contas/view",
    name: "PlanoContas",
    component: () => import("@/views/CadastroPlanoContasView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/plano-contas/plano-contas-referencial",
    name: "PlanoContasReferencialList",
    component: () => import("@/views/CadastroPlanoContasRefList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/financeiro/condicoes-pagamento",
    name: "ListConditionsPayments",
    component: () => import("@/views/CadastroCondicoesPagamentoList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/financeiro/condicoes-pagamento/view",
    name: "NewConditionsPayments",
    component: () => import("@/views/CadastroCondicoesPagamentoForm.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/ispb",
    name: "IspbList",
    component: () => import("@/views/CadastroIspbList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/produto/unidade-medida",
    name: "UnitProductList",
    component: () => import("@/views/CadastroUnidadeMedidaList"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/documentos/fiscais",
    name: "DocumentoFiscalList",
    component: () => import("@/views/CadastroDocumentoFiscalList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/historico",
    name: "HistoricoList",
    component: () => import("@/views/CadastroHistoricoList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/grupos/produtos",
    name: "ListGroupProducts",
    component: () => import("@/views/CadastroGrupoProdutoList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/grupos/produtos/view",
    name: "NewGroupProduct",
    component: () => import("@/views/CadastroGrupoProdutoView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/financeiro/administradora-cartoes",
    name: "AdministradoraCartoesList",
    component: () => import("@/views/CadastroAdministradoraList"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/administradora",
    name: "AdministradoraList",
    component: () => import("@/views/CadastroAdministradoraList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/administradora/view",
    name: "Administradora",
    component: () => import("@/views/CadastroAdministradoraView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/fiscais/cfop",
    name: "CfopList",
    component: () => import("@/views/CadastroCfopList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/fiscais/observacoes",
    name: "ObservacoesList",
    component: () => import("@/views/CadastroObservacoesList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/produtos",
    name: "ListProducts",
    component: () => import("@/views/CadastroProdutoList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/produtos/view",
    name: "ViewProduct",
    component: () => import("@/views/CadastroProdutoView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/fiscais/cest",
    name: "CestList",
    component: () => import("@/views/CadastroCestList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/fiscais/ncm",
    name: "NcmList",
    component: () => import("@/views/CadastroNcmLista.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/fiscais/ncm/registro",
    name: "Ncm",
    component: () => import("@/views/CadastroNcmForm.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/moedas",
    name: "Lista de Moedas",
    component: () => import("@/views/CadastroMoedasList"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/financeiro/centro-custo",
    name: "CentroCustoList",
    component: () => import("@/views/CadastroCentroCustoList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/financeiro/centro-custo/view",
    name: "CostCenter",
    component: () => import("@/views/CadastroCentroCustoView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/local/estoque",
    name: "LocalEstoque",
    component: () => import("@/views/CadastroLocalEstoqueList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/local/estoque/view",
    name: "StockLocations",
    component: () => import("@/views/CadastroLocaisEstoqueView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/formaPagamento/list",
    name: "FormaPagamentoList",
    component: () => import("@/views/CadastroFormaPagamentoList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/clientes",
    name: "Clientes - Lista",
    component: () => import("@/views/CadastroClientesList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/clientes/view",
    name: "Clientes - View",
    component: () => import("@/views/CadastroClientesView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/tipos-pagamento",
    name: "Tipos Pagamento - Lista",
    component: () => import("@/views/CadastroTiposPagamentosList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/pessoas",
    name: "PersonList",
    component: () => import("@/views/CadastroPessoaLista.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/produtos/seriais",
    name: "Cadastro Seriais - Produtos",
    component: () => import("@/views/CadastrosProdutosSeriais.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/fiscais/impostos/view",
    name: "Cadastro de Impostos",
    component: () => import("@/views/CadastroImpostosView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/fiscais/impostos",
    name: "Cadastro de Impostos",
    component: () => import("@/views/CadastroImpostosList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastros/fiscais/impostos/excecoes/view",
    name: "Cadastro de Impostos - Exceções",
    component: () => import("@/components/CadastroImpostosExcecoesTab.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastro/tipo-movimento-fiscal",
    name: "Tipo Movimento Fiscal",
    component: () => import("@/views/CadastroTipoMovimentoFIscalList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cadastro/tipo-movimento-fiscal/view",
    name: "Tipo Movimento Fiscal",
    component: () => import("@/views/CadastroTipoMovimentoFiscalView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];
