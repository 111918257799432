<template>
  <v-container>
    <v-img
      :width="width"
      :height="height"
      src="@/assets/logoPichau.png"
      alt="Logo"
      contain
      @click="redirectHome"
    />
  </v-container>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "200",
      required: true,
    },
    height: {
      type: String,
      default: "50",
      required: true,
    },
  },
  methods: {
    redirectHome() {
      this.$router.push("/");
    },
  },
};
</script>
