<template>
  <v-container fluid class="d-flex align-center justify-between">
    <!-- Logo -->
    <v-col class="d-flex">
      <BaseNavLogo class="logo-img" width="200" height="50" />
    </v-col>

    <v-col class="d-flex mt-4">
      <p v-html="erpBaseFilters" class="environment-label" />
    </v-col>

    <!-- Área de notificações e switches -->
    <v-row class="d-flex justify-end align-center">
      <v-col cols="auto" class="mr-2">
        <BaseNotification />
      </v-col>
      <v-col cols="auto" class="mr-2">
        <BaseThemeSwitch />
      </v-col>

      <!-- Menu de navegação -->
      <v-col v-for="(item, idx) in menuItems" :key="`menu-${idx}`" cols="auto">
        <v-menu :close-on-content-click="false" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="
                isAdmin ||
                (Array.isArray(item.role)
                  ? item.role.some((r) => userRoles.includes(r))
                  : userRoles?.includes(item.role))
              "
              v-bind="attrs"
              v-on="on"
              text
              class="no-uppercase"
            >
              <v-icon>{{ item.icon }}</v-icon>
              {{ item.title }}
            </v-btn>
          </template>

          <v-list class="menu-list">
            <div
              v-for="(subItem, subIdx) in item.items"
              v-show="
                isAdmin ||
                (Array.isArray(subItem.role)
                  ? subItem.role.some((r) => userRoles.includes(r))
                  : userRoles?.includes(subItem.role))
              "
              :key="`subGroup-${subIdx}`"
            >
              <v-list-group
                v-if="subItem.subitems?.length > 0"
                :title="subItem.name"
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-title
                    @click="subItem.subitems ? null : navigateTo(subItem.path)"
                  >
                    <v-icon>{{ subItem.icon }}</v-icon>
                    {{ subItem.name }}
                  </v-list-item-title>
                </template>

                <template v-slot:append-icon>
                  <v-icon>{{ "mdi-chevron-down" }}</v-icon>
                </template>

                <v-list-item
                  v-for="(child, childIdx) in subItem.subitems"
                  :key="`child-${childIdx}`"
                  class="menu-list-item"
                  @mousedown="handleMouseDown($event, child.path)"
                >
                  <v-list-item-title>
                    <v-icon>{{ child.icon }}</v-icon>
                    {{ child.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-group>

              <v-list-item
                v-else
                class="menu-list-item"
                @mousedown="handleMouseDown($event, subItem.path)"
              >
                <v-list-item-title>
                  <v-icon>{{ subItem.icon }}</v-icon>
                  {{ subItem.name }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="auto">
        <BaseNavLogout />
      </v-col>
    </v-row>
    <!-- Logout -->
  </v-container>
</template>

<script>
import BaseThemeSwitch from "@/components/BaseThemeSwitch.vue";
import BaseNotification from "@/components/BaseNotification.vue";
import BaseNavLogo from "@/components/BaseNavLogo.vue";
import BaseNavLogout from "@/components/BaseNavLogout.vue";

import { openTab } from "@/router/utils.js";
import { erpBaseFilters } from "@/util/environment";

export default {
  components: {
    BaseNavLogo,
    BaseNavLogout,
    BaseThemeSwitch,
    BaseNotification,
  },

  props: {
    menuItems: {
      type: Array,
      required: true,
    },
    userRoles: {
      type: String,
      required: false,
    },
    isAdmin: {
      type: Boolean,
      required: false,
    },
    navigateTo: {
      type: Function,
      required: true,
    },
  },
  setup() {
    return {
      erpBaseFilters,
    };
  },

  methods: {
    handleMouseDown(event, path) {
      const isSameRoute = this.$route.path === path;
      const openNewTab =
        event.button === 1 || (event.button === 0 && event.ctrlKey);

      if (openNewTab) {
        openTab(path);
      } else if (event.button === 0) {
        if (isSameRoute) {
          event.preventDefault();
          return;
        }
        this.$router.push(path);
      }
    },
  },
};
</script>

<style scoped>
.v-toolbar-title {
  cursor: pointer;
}

.logo-img {
  cursor: pointer;
  margin-right: auto;
}

.menu-list-item {
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.menu-list-item:hover {
  background-color: #999999;
  cursor: pointer;
  color: #1976d2;
}

.menu-list-item:active {
  background-color: #e0e0e0;
  color: #1976d2;
}

.menu-list-item:focus {
  outline: none;
  background-color: #e0e0e0;
}

.v-icon {
  font-size: 1.5rem;
}

.environment-label {
  font-size: 1.5rem;
}

@media (max-width: 1877px) {
  .environment-label {
    font-size: 1rem;
  }
}
</style>
