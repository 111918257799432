export const FinanceiroRoutes = [
  {
    path: "/financeiro/bordero",
    name: "Borderôs",
    component: () => import("@/views/FinanceiroBorderoList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/financeiro/bordero/view",
    name: "Borderô",
    component: () => import("@/views/FinanceiroBorderoView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/financeiro/movimentos/centro-custo",
    name: "Movimentos Centro Custo",
    component: () => import("@/views/FinanceiroMovimentoCentroCustoList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/financeiro/finalidade/list",
    name: "Finalidade Pagamento",
    component: () => import("@/views/FinanceiroFinalidadePagList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/financeiro/extrato-marketplace",
    name: "Extratos Marketplace",
    component: () => import("@/views/FinanceiroExtratoMarketplace.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/financeiro/caixa/resumos-saldo-conta",
    name: "Resumo Saldos Bancários",
    component: () => import("@/views/FinanceiroCaixaResumoSaldosBancarios.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/financeiro/contas-pagar",
    name: "ContasPagarList",
    component: () => import("@/views/FinanceiroContasPagarList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/financeiro/contas-receber",
    name: "ContasReceberList",
    component: () => import("@/views/FinanceiroContasReceberList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/financeiro/contas-receber/view",
    name: "ContasReceberForm",
    component: () => import("@/views/FinanceiroContasReceberView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/financeiro/contas-pagar/view",
    name: "ContasPagarForm",
    component: () => import("@/views/FinanceiroContasPagarView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/financeiro/conta-corrente/cliente",
    name: "ContaCorrenteClienteList",
    component: () => import("@/views/FinanceiroContaCorrenteClienteList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/financeiro/conta-corrente/resumo",
    name: "ContaCorrenteResumoList",
    component: () => import("@/views/FinanceiroContaCorrenteResumoList.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/financeiro/caixa",
    name: "Caixa",
    component: () => import("@/views/FinanceiroCaixaList.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/financeiro/caixa/view",
    name: "Novo Caixa",
    component: () => import("@/views/FinanceiroCaixaView.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/financeiro/conciliacao-marketplace",
    name: "Conciliação Marketplace",
    component: () =>
      import("@/views/financeiro/conciliacao/ConciliacaoMktGrid.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/financeiro/conciliacao-mkt",
    name: "Conciliação Marketplace",
    component: () => import("@/views/FinanceiroMktConciliacao.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/financeiro/refunds",
    name: "Reembolsos",
    component: () => import("@/views/FinanceiroRefundList"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/financeiro/contrato-cambio/view",
    name: "Contrato Câmbio",
    component: () => import("@/views/FinanceiroContratoCambioView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];
