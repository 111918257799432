import Vue from "vue";

function showSuccess(message, duration = 3500) {
  Vue.prototype.$notify({
    group: "alerts",
    title: message,
    type: "success",
    classes: ["vue-notification", "notif-success"],
    duration: duration,
  });
}

function showError(message, duration = 3500) {
  Vue.prototype.$notify({
    group: "alerts",
    title: message,
    type: "error",
    classes: ["vue-notification", "notif-error"],
    duration: duration,
  });
}

function showWarning(message, duration = 3500) {
  Vue.prototype.$notify({
    group: "alerts",
    title: message,
    type: "warning",
    classes: ["vue-notification", "notif-warning"],
    duration: duration,
    ignoreDuplicates: true,
  });
}

function showServerError(error, fallbackMessage) {
  if (error.response && error.response.data && error.response.data.code) {
    const { data } = error.response;
    showError(data.error);
  } else {
    showError(fallbackMessage);
  }
}

export { showSuccess, showError, showWarning, showServerError };
