<template>
  <v-container fluid class="d-flex align-center justify-between">
    <!-- Botão de menu para abrir/fechar -->
    <v-btn
      icon
      style="position: absolute; right: 16px"
      @click="drawer = !drawer"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <!-- Cabeçalho -->
    <v-row class="d-flex align-center">
      <v-col class="d-flex justify-start" cols="auto">
        <BaseNavLogo class="logo-img" width="200" height="50" />
      </v-col>
      <v-col class="d-flex justify-center" cols="auto">
        <p v-html="erpBaseFilters" class="headline mb-0" />
      </v-col>
    </v-row>

    <!-- Menu Gaveta -->
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      absolute
      width="350"
      class="v-navigation-drawer__custom"
    >
      <!-- Logo e notificações -->
      <BaseNavLogo width="300" height="150" />
      <v-divider class="mt-5" />
      <v-row justify="space-between" class="pa-0 mt-3 mb-3">
        <v-col cols="auto" class="pa-0 ml-5">
          <BaseNotification />
        </v-col>
        <v-col cols="auto" class="pa-0 mr-8">
          <BaseThemeSwitch />
        </v-col>
      </v-row>

      <!-- Itens do Menu -->
      <v-list>
        <div v-for="(item, idx) in localMenuItems" :key="`drawer-menu-${idx}`">
          <!-- Divider entre seções -->
          <v-divider v-if="idx !== localMenuItems.length" />

          <v-btn
            v-show="
              isAdmin ||
              (Array.isArray(item.role)
                ? item.role.some((r) => userRoles.includes(r))
                : userRoles?.includes(item.role))
            "
            text
            class="menu-btn"
            @click="toggleItemExpansion(idx)"
          >
            <v-icon>{{ item.icon }}</v-icon>
            {{ item.title }}
            <v-icon v-if="item.expanded">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </v-btn>

          <v-list v-if="item.expanded">
            <div
              v-for="(subItem, subIdx) in item.items"
              v-show="
                isAdmin ||
                (Array.isArray(subItem.role)
                  ? subItem.role.some((r) => userRoles.includes(r))
                  : userRoles?.includes(subItem.role))
              "
              :key="`subGroup-${subIdx}`"
            >
              <v-list-group
                v-if="subItem.subitems?.length > 0"
                :sub-group="subItem.subitems?.length > 0"
                :title="subItem.name"
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-title
                    @click="subItem.subitems ? null : navigateTo(subItem.path)"
                  >
                    <v-icon>{{ subItem.icon }}</v-icon>
                    {{ subItem.name }}
                  </v-list-item-title>
                </template>

                <v-list-item
                  v-for="(child, childIdx) in subItem.subitems"
                  :key="`child-${childIdx}`"
                  @click="navigateTo(child.path)"
                >
                  <v-list-item-title>
                    <v-icon>{{ child.icon }}</v-icon>
                    {{ child.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-group>

              <v-list-item v-else @click="navigateTo(subItem.path)">
                <v-list-item-title>
                  <v-icon>{{ subItem.icon }}</v-icon>
                  {{ subItem.name }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list>

          <v-divider />
        </div>

        <!-- Logout -->
        <BaseNavLogout />
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import BaseThemeSwitch from "@/components/BaseThemeSwitch.vue";
import BaseNotification from "@/components/BaseNotification.vue";
import BaseNavLogo from "@/components/BaseNavLogo.vue";
import BaseNavLogout from "@/components/BaseNavLogout.vue";

import { erpBaseFilters } from "@/util/environment";

export default {
  components: {
    BaseNavLogo,
    BaseNavLogout,
    BaseThemeSwitch,
    BaseNotification,
  },

  props: {
    menuItems: {
      type: Array,
      required: true,
    },
    userRoles: {
      type: String,
      required: false,
    },
    isAdmin: {
      type: Boolean,
      required: false,
    },
    navigateTo: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      drawer: false,
      localMenuItems: [...this.menuItems],
    };
  },
  setup() {
    return {
      erpBaseFilters,
    };
  },
  mounted() {
    if (this.localMenuItems.length > 0) {
      this.localMenuItems[0].expanded = true;
    }
  },
  methods: {
    toggleItemExpansion(idx) {
      this.localMenuItems = this.localMenuItems.map((item, i) => ({
        ...item,
        expanded: i === idx ? !item.expanded : false,
      }));
    },
  },
};
</script>

<style>
/* Estilo do botão de menu */
.menu-btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* Estilo para os itens selecionados */
.v-navigation-drawer__custom .v-list-item.selected {
  background-color: #1976d2;
}

/* Rolagem da gaveta */
.v-navigation-drawer__custom .v-navigation-drawer__content {
  max-height: 100vh;
  overflow-y: auto;
  padding: 8px;
  scrollbar-width: thin;
  scrollbar-color: gray transparent;
}

.v-list-item:hover {
  cursor: pointer;
}
/* Icones do menu */
.v-icon {
  font-size: 1.5rem;
}

.v-navigation-drawer {
  width: 350px;
}
</style>
