export const ComprasRoutes = [
  {
    path: "/compras/ordem-compra",
    name: "OrdemCompras",
    component: () => import("@/views/ComprasOrdemCompraList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/compras/ordem-compra/old",
    name: "OrdemComprasOld",
    component: () => import("@/views/ComprasOldOrdemComprasList"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/compras/ordem-compra/view",
    name: "OrdemCompra",
    component: () => import("@/views/ComprasOrdemCompraView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/compras/processos",
    name: "Processos - Ordens Compras",
    component: () => import("@/views/ComprasProcessosList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/compras/processos/create",
    name: "Criar Processo - Ordens Compras",
    component: () => import("@/views/ComprasProcessoCreate.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/compras/processos/view",
    name: "Ver Processo - Ordens Compras",
    component: () => import("@/views/ComprasProcessoView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/compras/processos/ramificar",
    name: "Ramificar Processo",
    component: () => import("@/views/ComprasProcessoRamificacao.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contrato-cambio/list",
    name: "Contrato de Câmbio",
    component: () => import("@/views/FinanceiroContratoCambioList..vue"),
    meta: {
      requiresAuth: true,
    },
  },
];
