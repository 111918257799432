export const LogisticaRoutes = [
  {
    path: "/logistica/fatura-transportadora",
    name: "Faturas - Lista",
    component: () => import("@/views/LogisticaFaturaTransportadoraList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logistica/fatura-transportadora/view",
    name: "Faturas - View",
    component: () => import("@/views/LogisticaFaturaTransportadoraView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logistica/conhecimento-transporte",
    name: "CTe - List",
    component: () => import("@/views/LogisticaConhecimentoTransporteList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];
