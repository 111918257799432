import { BasisRoutes } from "./modules/BasisRoutes";
import { RmaRoutes } from "./modules/RmaRoutes";
import { FinanceiroRoutes } from "./modules/FinanceiroRoutes";
import { FiscalRoutes } from "./modules/FiscalRoutes";
import { PedidosRoutes } from "./modules/PedidosRoutes";
import { CadastrosRoutes } from "./modules/CadastrosRoutes";
import { UsuariosRoutes } from "./modules/UsuariosRoutes";
import { ComprasRoutes } from "./modules/ComprasRoutes";
import { SistemaRoutes } from "./modules/SistemaRoutes";
import { LogisticaRoutes } from "./modules/LogisticaRoutes";
import { RhRoutes } from "./modules/RhRoutes";

export default [
  ...BasisRoutes,
  ...RmaRoutes,
  ...FinanceiroRoutes,
  ...FiscalRoutes,
  ...PedidosRoutes,
  ...CadastrosRoutes,
  ...UsuariosRoutes,
  ...ComprasRoutes,
  ...SistemaRoutes,
  ...LogisticaRoutes,
  ...RhRoutes,
];
