import { defineStore } from "pinia";
import { login, logout } from "@/services/auth.service";
import Login from "@/models/login";
import router from "@/router";
import { getEmpresas } from "@/services/empresas.service";
import { showServerError } from "@/util/alert";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: JSON.parse(localStorage.getItem("userToken'")) || null,
    login: new Login(null, "", ""),
    loading: false,
    empresasList: [],
    showPassword: false,
    capsLockActive: null,
    requiredFields: [
      { label: "Username", key: "username" },
      { label: "Senha", key: "password" },
    ],
  }),
  getters: {
    blockSave() {
      if (this.login.username && this.login.password) {
        return true;
      }
      return false;
    },
  },
  actions: {
    async doLogin(userCredentials) {
      try {
        this.loading = true;
        await login(userCredentials);
        localStorage.setItem("empresaId", this.login.empresaId);
        router.push("/");
      } catch (error) {
        showServerError(error, "Erro ao realizar login");
      } finally {
        this.loading = false;
      }
    },

    logout() {
      logout();
      this.login = new Login(null, "", "");
      router.push("/login");
    },

    async loadEmpresas() {
      const resp = await getEmpresas();

      if (resp.status === 200) {
        this.empresasList = resp.data.map((item) => {
          return { value: item.id, text: item.id + " - " + item.fantasia };
        });
        this.login.empresaId = this.empresasList[0].value;
      }
    },

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    checkCapsLock(event) {
      this.capsLockActive =
        (event &&
          event.getModifierState &&
          event.getModifierState("CapsLock")) ||
        false;
      localStorage.setItem("capsLock", this.capsLockActive);
    },
  },
});
