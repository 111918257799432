import router from "@/router/index.js";

export const openTab = function (path, query, dataToSend) {
  const previousPath = router.currentRoute.fullPath;
  localStorage.setItem("previousPath", previousPath);

  if (dataToSend) {
    sessionStorage.setItem("dataToSend", JSON.stringify(dataToSend));
  }

  const routeData = router.resolve({
    path: path,
    query: query,
  });

  window.open(routeData.href, "_blank");
};
export const goBack = function (defaultPath) {
  const previousPath = localStorage.getItem("previousPath");

  if (previousPath) {
    router.push(previousPath);
    localStorage.removeItem("previousPath");
  } else {
    router.push(defaultPath);
  }
};
