import Stomp from "webstomp-client";
import { handleWebSocketMessage } from "./webSocketUtils";

export default class WebSocketConnection {
  constructor(apiPath) {
    this.apiPath = apiPath;
    this.stompClient = null;
    this.webSocket = null;
  }

  connect(asyncResponse) {
    const wsUrl = `${this.apiPath}/ws-updates`;
    this.webSocket = new WebSocket(wsUrl);
    this.stompClient = Stomp.over(this.webSocket);

    const userToken = localStorage.getItem("userToken");
    const headers = { Authorization: `${userToken}` };

    this.stompClient.connect(
      headers,
      () => {
        this.subscribeToTopic(asyncResponse);
      },
      (error) => {
        console.error("Erro ao conectar ao WebSocket", error);
      }
    );

    this.webSocket.onerror = (error) => {
      console.error("Erro no WebSocket:", error);
    };

    this.webSocket.onclose = () => {
      console.debug("WebSocket desconectado");
    };
  }

  subscribeToTopic(asyncResponse) {
    const { notificationType, processId } = asyncResponse;
    const topicToSubscribe = `/topic/${notificationType}/${processId}`;
    this.stompClient.subscribe(topicToSubscribe, (message) => {
      if (message.body) {
        try {
          const parsedMessage = JSON.parse(message.body);
          handleWebSocketMessage(parsedMessage, notificationType);
        } catch (error) {
          console.error("Erro ao parsear a mensagem:", error);
        }
      } else {
        console.debug("Nenhuma mensagem recebida.");
      }
      this.disconnect();
    });
  }

  disconnect() {
    if (this.stompClient) {
      this.stompClient.disconnect(() => {
        console.debug("Desconectado do WebSocket.");
      });
    }
  }
}
