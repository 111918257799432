export const RhRoutes = [
  {
    path: "/rh/diario-horas",
    name: "Diario Horas - Lista",
    component: () => import("@/views/RhDiarioHorasList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];
