import { defineStore } from "pinia";
import Stomp from "webstomp-client";

function safeJsonParse(str) {
  try {
    return JSON.parse(str);
  } catch (error) {
    console.error("Erro ao parsear JSON:", error);
    return null;
  }
}

export const useNotificationStore = defineStore("notificationStore", {
  state: () => ({
    receivedMessages: [],
    notifications: [],
    notificationConnected: false,
    progressConnected: false,
    notificationStompClient: null,
    progressStompClient: null,
    progressBuffer: [],
  }),

  actions: {
    async connect() {
      const userToken = localStorage.getItem("userToken");

      if (userToken) {
        this.connectNotificationSocket(userToken);
      } else {
        console.warn(
          "Token do usuário não encontrado. Ignorando conexão de notificações."
        );
      }

      this.connectProgressSocket();
    },

    connectNotificationSocket(token) {
      const notificationSocket = new WebSocket(
        `${process.env.VUE_APP_WS_PATH}/notifications`
      );
      this.notificationStompClient = Stomp.over(notificationSocket);

      const headers = { Authorization: `${token}` };

      this.notificationStompClient.connect(
        headers,
        () => {
          this.notificationConnected = true;
          this.notificationStompClient.subscribe(
            "/user/queue/notifications",
            this.handleNotificationMessage.bind(this)
          );
        },
        (error) => {
          this.notificationConnected = false;
          console.error("Erro na conexão de notificações:", error);
        }
      );
    },

    handleNotificationMessage(tick) {
      const notificationResponse = safeJsonParse(tick.body);
      if (!notificationResponse) return;

      notificationResponse.type = "notification";
      notificationResponse.isShown = true;
      this.notifications.push(notificationResponse);
      this.receivedMessages.push(notificationResponse);
      this.timeoutDismiss();
    },

    connectProgressSocket() {
      const progressSocket = new WebSocket(
        `${process.env.VUE_APP_WS_PATH}/notifications`
      );
      this.progressStompClient = Stomp.over(progressSocket);

      this.progressStompClient.connect(
        {},
        () => {
          this.progressConnected = true;
          this.progressStompClient.subscribe(
            "/topic/progress-updates",
            this.handleProgressMessage.bind(this)
          );
        },
        (error) => {
          console.error("Erro na conexão de progresso:", error);
        }
      );
    },

    handleProgressMessage(message) {
      const messageBody = safeJsonParse(message.body);
      if (!messageBody) return;

      this.progressBuffer.push(messageBody);
      this.flushProgressUpdates();
    },

    flushProgressUpdates() {
      this.progressBuffer.forEach((progress) => {
        const progressData = safeJsonParse(progress.notification);
        if (!progressData) return;

        const processId = progressData.processId;
        const existingIndex = this.receivedMessages.findIndex((item) => {
          const parsedItem = safeJsonParse(item.notification);
          if (!parsedItem) return false;

          let itemProcessId = parsedItem.processId;
          if (!itemProcessId && parsedItem.notification) {
            const nested = safeJsonParse(parsedItem.notification);
            itemProcessId = nested ? nested.processId : undefined;
          }
          return itemProcessId === processId;
        });

        if (existingIndex !== -1) {
          const updatedMessage = {
            ...this.receivedMessages[existingIndex],
            notification: JSON.stringify(progressData),
            percentual: progress.percentual,
          };

          this.receivedMessages.splice(existingIndex, 1, updatedMessage);
        } else {
          this.receivedMessages.push({
            ...progress,
            class: "progress",
            isShown: true,
            percentual: progress.percentual,
          });
        }
      });

      this.progressBuffer = [];
      this.timeoutDismiss();
    },

    timeoutDismiss() {
      setTimeout(() => {
        this.receivedMessages = this.receivedMessages.filter(
          (msg) => msg.class !== "progress" || msg.percentual === 100
        );
      }, 5000);
    },

    disconnect() {
      if (this.notificationStompClient && this.notificationConnected) {
        this.notificationStompClient.disconnect(() => {
          this.notificationConnected = false;
          console.log("WebSocket de notificações desconectado.");
        });
      }
      if (this.progressStompClient && this.progressConnected) {
        this.progressStompClient.disconnect(() => {
          this.progressConnected = false;
          console.log("WebSocket de progresso desconectado.");
        });
      }
    },
  },
});
