export const SistemaRoutes = [
  {
    path: "/sistema/faq",
    name: "FAQ",
    component: () => import("@/views/SistemaDuvidasBugsView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sistema/agendamentos",
    name: "Agendamentos - Lista",
    component: () => import("@/views/SistemaAgendamentosList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];
