<template>
  <v-btn
    :dark="$vuetify.theme.dark"
    text
    class="mt-5 mb-5 no-uppercase"
    @click="logout"
  >
    Sair
    <v-icon class="ml-2" dense> mdi-logout </v-icon>
  </v-btn>
</template>

<script>
import { useAuthStore } from "@/store/globals.auth.store";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const store = useAuthStore();

    const { logout } = storeToRefs(store);
    return {
      logout,
    };
  },
};
</script>
