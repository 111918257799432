import { API_URLS } from "@/apis/apiTypes";
import WebSocketConnection from "@/util/webSocket.js";
import axios from "axios";
import jwtDecode from "vue-jwt-decode";

const getApiBaseUrl = (apiType) => {
  return API_URLS[apiType] || process.env.VUE_APP_API_PATH;
};

class API {
  constructor(apiType = "ERP", contentType = "application/json") {
    this.apiType = apiType;
    this.contentType = contentType;
    this.token = localStorage.getItem("userToken") || null;

    const headers = {
      "Content-Type": this.contentType,
    };

    if (this.token && jwtDecode.decode(this.token)) {
      headers.Authorization = this.token;
    }

    this.axiosInstance = axios.create({
      baseURL: getApiBaseUrl(this.apiType),
      headers,
    });

    this.setupInterceptors(headers);
  }

  setupInterceptors() {
    this.axiosInstance.interceptors.response.use(
      (response) => {
        const asyncResponse = response.data?.async;
        if (asyncResponse) {
          this.connectWebSocket(asyncResponse);
        }
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  connectWebSocket(asyncResponse) {
    const wsConnection = new WebSocketConnection(process.env.VUE_APP_WS_PATH);
    wsConnection.connect(asyncResponse);
    this.wsConnection = wsConnection;
  }

  get axios() {
    return this.axiosInstance;
  }
}

export default API;
