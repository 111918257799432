import { useLoadingStore } from "@/store/globals.loading.store";

export const showLoading = (persistent = false) => {
  const loadingStore = useLoadingStore();
  loadingStore.showLoading();

  // Define um timeout para ocultar o loading automaticamente após 3 segundos, caso alguém esqueça de chamar o hideLoading

  if (!persistent)
    setTimeout(() => {
      loadingStore.hideLoading();
    }, 10000);
};

export const hideLoading = () => {
  const loadingStore = useLoadingStore();
  loadingStore.hideLoading();
};
