export const API_TYPES = {
  ERP: "ERP",
  NCM: "NCM",
  NFE: "NFE",
  AUTH: "AUTH",
};

// Verifica se o ambiente é local

// Define as URLs com base no ambiente
export const API_URLS = {
  ERP: `${process.env.VUE_APP_API_PATH_ERP}`,
  NCM: `${process.env.VUE_APP_API_PATH_NCM}`,
  NFE: `${process.env.VUE_APP_API_PATH_NFE}`,
  AUTH: `${process.env.VUE_APP_API_PATH_AUTH}`,
};
