var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"d-flex align-center justify-between",attrs:{"fluid":""}},[_c('v-btn',{staticStyle:{"position":"absolute","right":"16px"},attrs:{"icon":""},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-icon',[_vm._v("mdi-menu")])],1),_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"auto"}},[_c('BaseNavLogo',{staticClass:"logo-img",attrs:{"width":"200","height":"50"}})],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"auto"}},[_c('p',{staticClass:"headline mb-0",domProps:{"innerHTML":_vm._s(_vm.erpBaseFilters)}})])],1),_c('v-navigation-drawer',{staticClass:"v-navigation-drawer__custom",attrs:{"app":"","temporary":"","absolute":"","width":"350"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('BaseNavLogo',{attrs:{"width":"300","height":"150"}}),_c('v-divider',{staticClass:"mt-5"}),_c('v-row',{staticClass:"pa-0 mt-3 mb-3",attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"pa-0 ml-5",attrs:{"cols":"auto"}},[_c('BaseNotification')],1),_c('v-col',{staticClass:"pa-0 mr-8",attrs:{"cols":"auto"}},[_c('BaseThemeSwitch')],1)],1),_c('v-list',[_vm._l((_vm.localMenuItems),function(item,idx){return _c('div',{key:`drawer-menu-${idx}`},[(idx !== _vm.localMenuItems.length)?_c('v-divider'):_vm._e(),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.isAdmin ||
            (Array.isArray(item.role)
              ? item.role.some((r) => _vm.userRoles.includes(r))
              : _vm.userRoles?.includes(item.role))
          ),expression:"\n            isAdmin ||\n            (Array.isArray(item.role)\n              ? item.role.some((r) => userRoles.includes(r))\n              : userRoles?.includes(item.role))\n          "}],staticClass:"menu-btn",attrs:{"text":""},on:{"click":function($event){return _vm.toggleItemExpansion(idx)}}},[_c('v-icon',[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title)+" "),(item.expanded)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1),(item.expanded)?_c('v-list',_vm._l((item.items),function(subItem,subIdx){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.isAdmin ||
              (Array.isArray(subItem.role)
                ? subItem.role.some((r) => _vm.userRoles.includes(r))
                : _vm.userRoles?.includes(subItem.role))
            ),expression:"\n              isAdmin ||\n              (Array.isArray(subItem.role)\n                ? subItem.role.some((r) => userRoles.includes(r))\n                : userRoles?.includes(subItem.role))\n            "}],key:`subGroup-${subIdx}`},[(subItem.subitems?.length > 0)?_c('v-list-group',{attrs:{"sub-group":subItem.subitems?.length > 0,"title":subItem.name,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{on:{"click":function($event){subItem.subitems ? null : _vm.navigateTo(subItem.path)}}},[_c('v-icon',[_vm._v(_vm._s(subItem.icon))]),_vm._v(" "+_vm._s(subItem.name)+" ")],1)]},proxy:true}],null,true)},_vm._l((subItem.subitems),function(child,childIdx){return _c('v-list-item',{key:`child-${childIdx}`,on:{"click":function($event){return _vm.navigateTo(child.path)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v(_vm._s(child.icon))]),_vm._v(" "+_vm._s(child.name)+" ")],1)],1)}),1):_c('v-list-item',{on:{"click":function($event){return _vm.navigateTo(subItem.path)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v(_vm._s(subItem.icon))]),_vm._v(" "+_vm._s(subItem.name)+" ")],1)],1)],1)}),0):_vm._e(),_c('v-divider')],1)}),_c('BaseNavLogout')],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }