export const UsuariosRoutes = [
  {
    path: "/users/list",
    name: "ListUsers",
    component: () => import("@/views/UsuarioUserList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/user",
    name: "User",
    component: () => import("@/views/UsuariosUserView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/list/ad",
    name: "UsuarioListAd",
    component: () => import("@/views/UsuarioListAd.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/user/ad",
    name: "UsuarioAd",
    component: () => import("@/views/UsuarioAdView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/profiles/list",
    name: "ListProfile",
    component: () => import("@/views/UsuarioPerfilList"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/profiles/profile",
    name: "ProfileView",
    component: () => import("@/views/UsuarioPerfilView"),
    meta: {
      requiresAuth: true,
    },
  },
];
