import { defineStore } from "pinia";

export const useLoadingStore = defineStore("loadingStore", {
  state: () => ({
    isLoading: false,
  }),
  actions: {
    showLoading() {
      this.isLoading = true;
    },
    hideLoading() {
      this.isLoading = false;
    },
  },
});
