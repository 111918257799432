import { eventBus } from "@/util/eventBus";
import { NotificationType } from "@/util/notifcationTypes";

export function piniaEventBusPlugin({ store }) {
  if (typeof store.handleAsyncRequestDone === "function") {
    eventBus.on(
      NotificationType.REQUEST_DONE,
      store.handleAsyncRequestDone.bind(store)
    );
  }
}
