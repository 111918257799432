export const RmaRoutes = [
  {
    path: "/rma/processos",
    name: "Processos de RMA",
    component: () => import("@/views/RmaProcessoList.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/rma/processos/novo",
    name: "Novo RMA",
    component: () => import("@/views/RmaProcessoView.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/rma/transportadora",
    name: "Aguardando Transportadora",
    component: () => import("@/views/RmaDefinicaoTransportadoraList.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/rma/transportadora/definir",
    name: "Definir Transportadora",
    component: () => import("@/views/RmaDefinicaoTransportadoraView.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/rma/emissao-nota",
    name: "Aguardando Emissão de Nota",
    component: () => import("@/views/RmaAguardandoEmissaoNotaList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/rma/recebidos",
    name: "RMAs Recebidos",
    component: () => import("@/views/RmaRecebidoList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/rma/solicitacao-troca",
    name: "Solicitação de Troca",
    component: () => import("@/views/RmaSolicitacaoTrocaList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/rma/servicos",
    name: "Ordens de Serviço",
    component: () => import("@/views/RmaOrdemServicoList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/rma/servicos/view",
    name: "Nova O.S",
    component: () => import("@/views/RmaOrdemServicoView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/rma/relatorios/substituicao",
    name: "RmaRelacaoSubstituicaoList",
    component: () => import("@/views/RmaRelacaoSubstituicaoList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];
