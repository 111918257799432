<template>
  <v-app>
    <div class="container-contents">
      <v-main>
        <router-view />
      </v-main>
    </div>

    <BaseLoading />
    <BaseHeaderNav v-if="!['Login'].includes($route.name)" />

    <notifications
      group="alerts"
      position="bottom center"
      class="notify-bar"
      data-cy="notification"
    />
  </v-app>
</template>

<script>
import BaseLoading from "./components/general/BaseLoading";
import BaseHeaderNav from "./components/BaseHeaderNav.vue";

import { useNotificationStore } from "@/store/notification.store";
import { storeToRefs } from "pinia";

export default {
  name: "App",
  components: {
    BaseLoading,
    BaseHeaderNav,
  },

  data() {
    return {
      router: this.$route.path,
    };
  },

  setup() {
    const store = useNotificationStore();
    const { connect, disconnect } = storeToRefs(store);
    return { connect, disconnect };
  },

  mounted() {
    this.connect();
  },

  beforeDestroy() {
    this.disconnect();
  },
};
</script>

<style scoped>
.container-contents {
  display: flex;
  height: 100%;
  margin-bottom: 0;
  margin-top: 0;
}

.notify-bar {
  display: flex;
  text-align: center;
}
</style>
